import { cloneDeep } from 'lodash-es'
import { approverStatus, userProjectRoles } from '@/server/constants'

export const state = () => ({
  activeDelegator: {},
  importedParticipantErrors: [],
  impersonatedProjectParticipantId: null,
  projectParticipant: {},
  projectParticipantApprovers: [],
  activeParticipant: {},
  activeParticipantDelegators: [],
  participants: [],
  participantCount: 0,
  participantListFilterOptions: {
    status: 'all',
    sortDirection: 'asc',
    sortField: 'created',
    listLimit: '50',
    listSkip: '',
    searchTerm: ''
  },
  participantSearchList: [],
  participantSearchParams: {
    firstName: '',
    lastName: '',
    email: '',
    orgName: '',
    country: '',
    state: '',
    county: '',
    community: '',
    zip: '',
    searchFormType: ''
  },
  participantUserList: [],
  participantOrgList: [],
  participantUserOrgList: [],
  participantEmailDomain: '',
  searchFlags: {
    orgSelected: false,
    contactSelected: false
  },
  userExistsEmail: '',
  userExistsOrgName: '',
  userExistsErrorType: '',
  userExistsErrorMessage: ''
})

export const getters = {
  getActiveDelegator: state => state.activeDelegator,
  getActiveParticipant: state => state.activeParticipant,
  getImportedParticipantErrors: state => state.importedParticipantErrors,
  getProjectParticipant: state => state.projectParticipant,
  getProjectParticipantApprovers: state => state.projectParticipantApprovers,
  getActiveParticipantDelegators: state => state.activeParticipantDelegators,
  getImpersonatedProjectParticipantId: state => state.impersonatedProjectParticipantId,
  getProjectParticipantPropertyCount: state => state.projectParticipant.propertyCount,
  getParticipants: state => state.participants,
  getParticipantCount: (state) => {
    return state.participantCount
  },
  // return list of participants that the current project participant is managing
  getParticipantsDelegatorIsApprovingList: (state) => {
    const participants = state.participants.filter((participant) => {
      return participant.approvers?.some(approver => approver.participant === state.projectParticipant._id && approver.status !== approverStatus.notapproving)
    })
    return participants
  },
  // determine if delegate/or is being managed by their immediate delegator
  getParticipantsDelegatorIsApproving: state => (participant) => {
    if (participant) {
      return participant.approvers?.some((approver) => {
        const delegatorId = (participant.delegator?._id || participant.delegator)
        return approver.participant === delegatorId && approver.status !== approverStatus.notapproving
      })
    }
    return false
  },
  // determine if a project participant is in approvers array for current delegate/or
  getProjectParticipantApprovingDelegate: state => (projPart = null, delegate = null) => {
    if (projPart && delegate) {
      return delegate.approvers?.some((approver) => {
        return String(approver.participant) === String(projPart._id) && approver.status !== approverStatus.notapproving
      })
    }
    return false
  },
  getParticipantSearchList: state => state.participantSearchList,
  getParticipantSearchParams: state => state.participantSearchParams,
  getParticipantUserList: state => state.participantUserList,
  getParticipantOrgList: state => state.participantOrgList,
  getParticipantUserOrgList: state => state.participantUserOrgList,
  getParticipantEmailDomain: state => state.participantEmailDomain,
  getSearchFlags: state => state.searchFlags,
  getUserExistsEmail: state => state.userExistsEmail,
  getUserExistsOrgName: state => state.userExistsOrgName,
  getUserExistsErrorType: state => state.userExistsErrorType,
  getUserExistsErrorMessage: state => state.userExistsErrorMessage,
  getUserExistsError: state => ({ email: state.userExistsEmail, orgName: state.userExistsOrgName, errorType: state.userExistsErrorType, message: state.userExistsErrorMessage })
}

export const actions = {

  setActiveDelegator({ commit }, delegator) {
    commit('setActiveDelegator', delegator)
  },

  setActiveParticipant({ commit }, participant) {
    commit('setActiveParticipant', participant)
  },

  // This adds a new collaborator to the project but after completion, the project will have to be reloaded.
  async addCollaboratorForParticipant({ dispatch }, { projectId, participant }) {
    try {
      const newParticipant = await this.$axios.$post(`/participant/collaborator/${projectId}`, { participant })
      return newParticipant
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/addCollaboratorForParticipant', info: err },
        { root: true })
    }
  },

  /**
   * add participant
   * participant: { firstName, lastName, email, orgName, country, state }
   * participant: { _id, orgName, orgId, orgContact, contactTitle, contactEmail, contactPhone, delegator, isDelegator, approveStatus, sendInvite }
   */
  async addParticipantToProject({ dispatch, commit }, { projectId, participant }) {
    try {
      const newParticipant = await this.$axios.$post(`/participant/${projectId}`, { participant })
      await dispatch('fetchProjectParticipants', { projectId })
      return newParticipant
    } catch (err) {
      const {
        response: {
          status: statusCode = 0,
          data = {}
        } = {}
      } = err || {}
      if (statusCode === 409) {
        const { message = '', user = {} } = data || {}
        const { userOrgs, foundEmailDomain } = user || {}
        const response = { ...user, message, type: user.searchEmail ? 'email' : 'org' }
        commit('setParticipantUserOrgList', userOrgs)
        commit('setParticipantEmailDomain', foundEmailDomain)
        dispatch('setUserExistsError', response)
        return response
      }
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/addParticipantToProject', info: err },
        { root: true })
    }
  },

  async addParticipantsToProject({ dispatch, commit }, { projectId, file }) {
    try {
      const data = new FormData()
      data.append('participants', file)
      const {
        importCount = 0,
        importTotal = 0,
        participantImportErrors
      } = await this.$axios.$post(`/participants/import/${projectId}`, data, {
        headers: {
          'Content-Type': 'multiplart/form-data'
        }
      })
      commit('setImportedParticipantErrors', participantImportErrors)
      const errorCount = participantImportErrors.length
      return { importCount, errorCount, importTotal }
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/addParticipantsToProject', info: err },
        { root: true })
    }
  },

  // delete participant
  async deleteParticipantFromProject({ commit, dispatch }, { projectId, participantId }) {
    try {
      const res = await this.$axios.delete(`/participant/${projectId}/${participantId}`)
      return res.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/deleteParticipantFromProject', info: err },
        { root: true })
    }
  },

  /**
   * search: { country: 'United States', state: 'Missouri', county: 'Jackson', community: 'Kansas City', zip: '64111' }
   */
  // TODO: update api to add country
  async fetchParticipantsByLocation({ commit, dispatch }, { projectId, search, limit, skip, sortby }) {
    try {
      const participants = await this.$axios.$post(
        `/participants/${projectId}/location/search`,
        { search, limit, skip, sortby })
      commit('setParticipantSearchList', participants)
      commit('setParticipantSearchParams', { search, searchType: 'location' })
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchParticipantsByLocation', info: err },
        { root: true }
      )
    }
  },

  /**
   * search: { orgName: 'Blah', firstName: 'Susan', lastName: 'Woolworth', email: 'susan.woolworth@blueskybots.com' }
   */
  async fetchParticipantsByPersonOrOrg({ commit, dispatch }, { projectId, search, limit, skip, sortby, collaborators = false }) {
    try {
      const participants = await this.$axios.$post(
        `/participants/${projectId}/personorg/search`,
        { search, limit, skip, sortby, collaborators })
      commit('setParticipantSearchList', participants)
      commit('setParticipantSearchParams', { search, searchType: 'person' })
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchParticipantsByPersonOrOrg', info: err },
        { root: true }
      )
    }
  },

  async fetchParticipantPropertyCount({ state, commit, dispatch }) {
    try {
      if (!state.projectParticipant._id) {
        return false
      }
      const participantId = state.projectParticipant._id
      const { propertyCount } = await this.$axios.$get(`/participant/${participantId}/property/count`)
      commit('setProjectParticipantPropertyCount', propertyCount)
      return propertyCount
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchParticipantPropertyCount', info: err },
        { root: true }
      )
    }
  },

  // list participants
  async fetchProjectParticipants({ commit, dispatch }, { projectId }) {
    try {
      const { participants, participantCount } = await this.$axios.$get(`/participants/${projectId}`)
      commit('setProjectParticipants', participants)
      commit('setProjectParticipantCount', participantCount)
      return participants
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchProjectParticipants', info: err },
        { root: true })
    }
  },

  async fetchProjectDelegatorsForDelegator({ commit, dispatch }, { projectId, participantId }) {
    try {
      const { participants } = await this.$axios.$get(`/participant/delegators/${projectId}/${participantId}`)
      commit('setActiveParticipantDelegators', participants)
      return participants
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchProjectDelegatorsForDelegator', info: err },
        { root: true })
    }
  },

  // fetch participant by id
  async fetchProjectParticipantById({ commit, dispatch }, { projectId, participantId }) {
    try {
      const participant = await this.$axios.$get(`/participant/${projectId}/${participantId}`)
      commit('setActiveParticipant', participant)
      return participant
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchProjectParticipantById', info: err },
        { root: true })
    }
  },

  async fetchParticipantApproversForDelegator({ commit, dispatch }, { projectId, participantId }) {
    try {
      const { approvers } = await this.$axios.$get(`/participant/approvers/${projectId}/${participantId}`)
      commit('setProjectParticipantApprovers', approvers)
      return approvers
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/fetchParticipantApproversForDelegator', info: err },
        { root: true })
    }
  },

  flagSelectedOrg({ commit }, flag) {
    commit('flagSelectedOrg', flag)
  },

  flagSelectedContact({ commit }, flag) {
    commit('flagSelectedContact', flag)
  },

  async impersonateProjectParticipant({ commit, dispatch }, { projectId, projectParticipantId }) {
    try {
      // Check with the server to see if we should be collaborating
      const result = await this.$axios.$post(`/participant/impersonate/${projectId}/${projectParticipantId}`)
      if (result.auth === 'ok' && result.actingAsParticipant) {
        // Project is, we need to keep a session variable for this since we can't read the cookie
        sessionStorage.setItem('impersonatedProjectParticipant', projectParticipantId)
        commit('setImpersonatedProjectParticipantId', projectParticipantId)
        return true
      }
      sessionStorage.setItem('impersonatedProjectParticipant', null)
      sessionStorage.removeItem('impersonatedProjectParticipant')
      commit('setImpersonatedProjectParticipantId', null)
      return true
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/impersonatedProjectParticipant', info: err },
        { root: true })
    }
  },

  async removeCollaboratorFromProject({ commit, dispatch }, { projectId, collaboratorId }) {
    try {
      const result = await this.$axios.$delete(`/participant/collaborator/${projectId}/${collaboratorId}`)
      return result.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/removeCollaboratorFromProject', info: err },
        { root: true })
    }
  },

  // search for organization by name (autocomplete for participant add)
  async searchOrganizationsByName({ state, commit, dispatch }, search) {
    try {
      if (state.searchFlags.contactSelected) {
        commit('setParticipantOrgs', cloneDeep(state.participantOrgList))
        return
      }
      const organizations = await this.$axios.$get(
        '/participants/organizations/search', { params: { search } })
      commit('setParticipantOrgs', organizations)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/searchOrganizationsByName', info: err },
        { root: true })
    }
  },

  // Use this after retrieving a user, get their orgId and fill in the organization
  async searchOrganizationById({ commit, dispatch }, organizationId) {
    try {
      const organization = await this.$axios.$get(
        '/participant/organization/search', { params: { organizationId } })
      return organization
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/searchOrganizationById', info: err },
        { root: true })
    }
  },

  // For contact name autocomplete drop-downs
  async searchUsersByName({ state, commit, dispatch }, search) {
    try {
      if (state.searchFlags.orgSelected) {
        commit('setParticipantUsers', cloneDeep(state.participantUserList))
        return
      }
      const users = await this.$axios.$get(
        '/participants/name/search', { params: { search } })
      commit('setParticipantUsers', users)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/searchUserByName', info: err },
        { root: true })
    }
  },

  // For email autocomplete drop-down
  async searchUsersByEmail({ state, commit, dispatch }, search) {
    try {
      if (state.searchFlags.orgSelected) {
        commit('setParticipantUsers', cloneDeep(state.participantUserList))
        return
      }
      const users = await this.$axios.$get(
        '/participants/email/search', { params: { search } })
      commit('setParticipantUsers', users)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/searchUsersByEmail', info: err },
        { root: true })
    }
  },

  async setActiveProjectAndParticipant({ state, commit, dispatch, rootGetters }, { projectId, isParticipant, participant = null }) {
    try {
      await dispatch(
        'projects/setActiveProjectId',
        projectId,
        { root: true }
      )
      if (!state.impersonateProjectParticipantId) {
        // Only search for a sessionStorage item if the state value does not exist, which means we
        // will search every time we're NOT impersonating a participant, but that's ok
        const projectParticipantId = sessionStorage.getItem('impersonatedProjectParticipant')
        if (projectParticipantId) {
          commit('setImpersonatedProjectParticipantId', projectParticipantId)
        }
      }
      if (isParticipant) {
        const user = rootGetters['user/user']
        const project = rootGetters['projects/getActiveProject']
        const projectParticipant = participant || project.participants.find(
          p => String(p.user._id || p.user) === String((user || {})._id) ||
               String(p._id) === String(state.impersonatedProjectParticipantId)
        )
        if (!projectParticipant) {
          throw new Error('Participant not found!')
        }
        await dispatch('setProjectParticipant', projectParticipant)
        await dispatch('user/addProjectRoleToActingAs', projectParticipant.role, { root: true })
        if (projectParticipant.role & userProjectRoles.delegator) {
          await dispatch('setActiveDelegator', projectParticipant)
        }
        return projectParticipant
      }
      return null
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/setActiveProjectAndParticipant', info: err },
        { root: true })
    }
  },

  async setProjectParticipationForParticipant({ state, commit, dispatch },
    { projectId, participantId, participating }) {
    try {
      // participating should be true/false
      const res = await this.$axios.$put(`/participation/${projectId}/${participantId}/${participating}`)
      if (res.result === 'ok') {
        return true
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/setProjectParticipationForParticipant', info: err },
        { root: true }
      )
    }
  },

  async setProjectParticipant({ commit, dispatch }, participant) {
    try {
      const updatedParticipant = await this.$axios.$get(
        `/participant/${participant.project}/${participant._id}`
      )
      if (updatedParticipant) {
        commit('setProjectParticipant', updatedParticipant)
        return updatedParticipant
      }
      return false
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/setProjectParticipant', info: err },
        { root: true }
      )
    }
  },

  async setParticipantIsCollaborating({ commit, dispatch }, { participantId, isCollaborating: collaborating }) {
    try {
      const { isCollaborating = false } = await this.$axios.$put(`/participant/alloworg/${participantId}`, { isCollaborating: collaborating })
      commit('setParticipantIsCollaborating', isCollaborating)
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/setParticipantIsCollaborating', info: err },
        { root: true }
      )
    }
  },

  resetParticipantOrgs({ commit, state }) {
    commit('setParticipantOrgs', [])
  },

  resetParticipantUsers({ commit }) {
    commit('setParticipantUsers', [])
  },

  resetParticipantSearchList({ commit }) {
    commit('setParticipantSearchList', [])
  },

  resetParticipantSearchParams({ commit }) {
    commit('setParticipantSearchParams', { search: { orgName: '', email: '', firstName: '', lastName: '', state: '', country: '', county: '', community: '', zip: '' }, searchType: '' })
  },

  async updateParticipantById({ commit, dispatch }, { projectId, participantId, participantRole, delegatorId, participantApprovers }) {
    try {
      const participant = await this.$axios.$put(
        `/participant/${projectId}/${participantId}`,
        { participantRole, delegatorId, participantApprovers })
      commit('setActiveParticipant', participant)
      return participant
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/updateParticipantById', info: err },
        { root: true }
      )
    }
  },

  async updateParticipantStatus({ dispatch }, { participantId, status }) {
    try {
      const result = await this.$axios.$put(`/participant/status/${participantId}`, { status })
      return result.result === 'ok'
    } catch (err) {
      return dispatch(
        'global/setNetworkError',
        { method: 'participants/updateParticipantStatus', info: err },
        { root: true }
      )
    }
  },

  setUserExistsError({ commit }, { searchEmail, searchOrgName, type, message }) {
    commit('setUserExistsErrorType', type)
    commit('setUserExistsEmail', searchEmail)
    commit('setUserExistsOrgName', searchOrgName)
    commit('setUserExistsErrorMessage', message)
  },

  updateParticipantOrgList({ commit }, organizations) {
    commit('setParticipantOrgs', organizations)
  },

  updateParticipantUserList({ commit }, participants) {
    commit('setParticipantUsers', participants)
  },

  resetUserExistsError({ commit }) {
    commit('resetUserExistsError')
  }
}

export const mutations = {

  init(state) {
    state.activeDelegator = {}
    state.activeParticipant = {}
    state.importedParticipants = []
    state.projectParticipant = {}
    state.participants = []
    state.participantCount = 0
    state.participantListFilterOptions = {
      status: 'all',
      sortDirection: 'asc',
      sortField: 'created',
      listLimit: '50',
      listSkip: '',
      searchTerm: ''
    }
    state.participantSearchList = []
    state.participantSearchParams = {
      firstName: '',
      lastName: '',
      email: '',
      orgName: '',
      country: '',
      state: '',
      county: '',
      community: '',
      zip: '',
      searchFormType: ''
    }
    state.participantUserList = []
    state.participantOrgList = []
    state.searchFlags = {
      orgSelected: false,
      contactSelected: false
    }
    state.userExistsEmail = ''
    state.userExistsOrgName = ''
    state.userExistsErrorType = ''
    state.userExistsErrorMessage = ''
  },

  flagSelectedOrg(state, flag) {
    state.searchFlags.orgSelected = flag
  },

  flagSelectedContact(state, flag) {
    state.searchFlags.contactSelected = flag
  },

  setActiveDelegator(state, delegator) {
    state.activeDelegator = delegator
  },

  setActiveParticipant(state, participant) {
    state.activeParticipant = participant
  },

  setActiveParticipantDelegators(state, delegators) {
    state.activeParticipantDelegators = delegators
  },

  setImportedParticipantErrors(state, errors) {
    state.importedParticipantErrors = errors
  },

  setProjectParticipant(state, participant) {
    state.projectParticipant = participant
  },

  setProjectParticipantApprovers(state, approvers) {
    state.projectParticipantApprovers = approvers
  },

  setImpersonatedProjectParticipantId(state, projectParticipantId) {
    state.impersonatedProjectParticipantId = projectParticipantId
  },

  setParticipantEmailDomain(state, emailDomain) {
    state.participantEmailDomain = emailDomain
  },

  setParticipantIsCollaborating(state, isCollaborating) {
    state.projectParticipant.isCollaborating = isCollaborating
  },

  setProjectParticipantPropertyCount(state, propertyCount) {
    state.projectParticipant.propertyCount = propertyCount
  },

  setProjectParticipants(state, participants) {
    state.participants = participants
  },

  setProjectParticipantCount(state, count) {
    state.participantCount = count
  },

  setParticipantOrgs(state, orgs) {
    state.participantOrgList = orgs
  },

  setParticipantUsers(state, users) {
    state.participantUserList = users
  },

  setParticipantUserOrgList(state, userOrgs) {
    state.participantUserOrgList = userOrgs
  },

  setParticipantSearchList(state, searchList) {
    state.participantSearchList = searchList
  },

  setParticipantSearchParams(state, { search, searchType }) {
    state.participantSearchParams = { ...search }
    state.participantSearchParams.searchFormType = searchType
  },

  setUserExistsEmail(state, email) {
    state.userExistsEmail = email
  },

  setUserExistsOrgName(state, orgName) {
    state.userExistsOrgName = orgName
  },

  setUserExistsErrorType(state, errorType) {
    state.userExistsErrorType = errorType
  },

  setUserExistsErrorMessage(state, errorMessage) {
    state.userExistsErrorMessage = errorMessage
  },

  resetUserExistsError(state) {
    state.userExistsErrorMessage = ''
    state.userExistsErrorType = ''
    state.userExistsEmail = ''
    state.userExistsOrgName = ''
  }
}
